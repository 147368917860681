import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import imageHomeUsability from '../images/home-usability.png'
import imageHomeDesign from '../images/home-design.png'
import imageHomeTraining from '../images/home-training.png'
import { Partners } from "../components/Partners";
import { HeroText } from "../components/HeroText";

const IndexPage = () => (
  <Layout activeMenuItem={'home'}>
    <Seo title="Home" keywords={[`simplease`, `user experience`, `agency`]} />
    <HeroText>Strategisches Design für digitale Produkte, Usability-Analysen und
      Mitarbeiter-Training</HeroText>
    <div className="content-wrapper services">
      <div className="content pure-g">
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Usability-Analysen</h3>
          <Link to="/usability" title="Mehr zu Usability-Analysen" tabIndex={-1}>
            <img src={imageHomeUsability} width="200" style={{ borderRadius: '100%' }} alt="Usability-Analysen" />
          </Link>
          <p>Verbessern Sie Ihre Website, Apps und&nbsp;andere Software-Produkte, basierend&nbsp;auf unseren konkreten
            Lösungsvorschlägen.</p>
          <Link to="/usability">Mehr zu Usability-Analysen</Link>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Strategisches Design</h3>
          <Link to="/design" title="Unser Design-Prozess" tabIndex={-1}>
            <img src={imageHomeDesign} width="200" style={{ borderRadius: '100%' }} alt="Strategisches Design" />
          </Link>
          <p>Wir helfen Ihnen, Ihre Nutzer zu verstehen, entwickeln eine sinnvolle Design-Strategie und liefern
            getestete&nbsp;Prototypen.</p>
          <Link to="/design">Unser Design-Prozess</Link>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-3">
          <h3>Mitarbeiter-Training</h3>
          <Link to="/training" title="Workshops und Trainings" tabIndex={-1}>
            <img src={imageHomeTraining} width="200" style={{ borderRadius: '100%' }} alt="Mitarbeiter-Training" />
          </Link>
          <p>Ihre Mitarbeiter lernen, wie sie die Usability von Apps, Websites und Software-Anwendungen
            selbstständig&nbsp;optimieren.</p>
          <Link to="/training">Workshops und Trainings</Link>
        </div>
      </div>
    </div>

    <div className="content-wrapper secondary">
      <div className="content">
        <Partners />
        <h2 className={'hero-heading'}>Mit strategischem Design schaffen wir ein optimales
          Erlebnis für die Kunden unserer Geschäftspartner.</h2>
        <div className={'hero-heading'}><Link style={{fontSize: '0.875em'}} to={"/referenzen"}>zu unseren Referenzen &rarr;</Link></div>
      </div>
    </div>

    {/*<GetInContact />*/}
  </Layout>
);

export default IndexPage
